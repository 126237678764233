<template>
  <div class="panel">
    <div class="panel-item">
      <header>Нууц үг сэргээх</header>
    </div>
    <div class="panel-item">
      <div class="text-color-secondary mb20">Бүртгэлтэй нэвтрэх нэрээ оруулна уу. Таны имэйл хаяг руу нууц үг сэргээх код илгээх болно</div>
      <el-form
        class="login-form"
        :model="model"
        :rules="rules"
        @submit.native.prevent="onSubmit"
        ref="form"
      >
        <el-form-item prop="username" label="Нэвтрэх нэр:">
          <el-input
            v-model="model.username"
            placeholder=""
            prefix-icon="fas fa-mail-bulk"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            class="login-button block"
            type="primary"
            native-type="submit"
            @click="onSubmit()"
            >Илгээх</el-button
          >
        </el-form-item>
        <div class="text-center">
          <router-link class="inventory" to="/signIn">Нэвтрэх</router-link>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { sendForgotPassCode } from '../../../utils/auth.js'
export default {
  name: 'forgotPassword',
  data () {
    return {
      model: {
        username: ''
      },
      loading: false,
      rules: {
        username: [
          {
            required: true,
            message: 'Нэвтрэх нэр хоосон байна',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          sendForgotPassCode(this.model.username).then((response) => {
            this.loading = false
            this.$router.push({
              name: 'changePassword',
              params: {
                username: this.model.username
              }
            })
          }).catch(error => {
            this.loading = false
            this.$message.error(error)
          })
        } else {
          return null
        }
      })
    }
  }
}
</script>
